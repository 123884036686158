<template>
  <div v-if="experts" class="experts-container">
    <div class="experts">
      <div class="title-container">
        <SectionTitle>{{ title }}</SectionTitle>
        <NuxtLink :to="`${link?.url}/`">
          <div class="more">{{ link?.name }}</div>
        </NuxtLink>
      </div>

      <div class="content">
        <div v-if="expertSelected" class="info">
          <div class="name">{{ expertSelected.attributes.name }}</div>
          <NuxtLink :to="`/nos-conseillers/${expertSelected.attributes.slug}/`">
            <div class="title">
              Expert{{ expertSelected.attributes.isFemale ? 'e' : '' }}
              <div class="country">{{ expertSelected.attributes.expertise }}</div>
            </div>
          </NuxtLink>
          <div class="speech">{{ expertSelected.attributes.speech }}</div>
        </div>

        <div class="selector">
          <div class="slider-container">
            <SwiperArrows v-model="swiper" class="arrow" which="left" />
            <SwiperWrapper
              v-model="swiper"
              :slide-to-clicked-slide="true"
              :slides-per-view="breakpoint.isMobile ? 'auto' : 4"
              :space-between="20"
              looped-slides="2"
              class="photos"
              watch-slides-progress
              loop
            >
              <SwiperSlide
                v-for="expert of experts"
                :key="expert.id"
                v-slot="{ isActive, isVisible }"
              >
                <NuxtImg
                  v-if="expert.attributes.image_path && isVisible"
                  provider="twicpics"
                  :alt="expert.attributes.name"
                  :class="{ selected: isActive }"
                  :src="expert.attributes.image_path"
                  class="photo"
                  format="webp"
                  :width="140"
                  :height="180"
                />
              </SwiperSlide>
            </SwiperWrapper>
            <SwiperArrows v-model="swiper" class="arrow right" which="right" />
          </div>
        </div>
      </div>

      <div class="hours-and-phone">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="hours" v-html="contactHtml"></div>
        <div class="phones">
          <div v-for="phone of phones" :key="phone.from" class="phone">
            <Icon class="icon" name="phone-alt" />
            <div class="label">Tel ({{ phone.from }}) :</div>
            <div class="label mobile">Tel ({{ phone.fromMobile }}) :</div>
            <div class="value">
              <a :href="`tel:${phone.value}`">{{ phone.value }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="expertSelected" class="contact-container">
        <NuxtLink :to="`/nos-conseillers/${expertSelected?.attributes.slug}/`">
          <div class="contact">
            {{
              replacePlaceholders(cta?.label ?? '', {
                expertise: expertSelected.attributes.expertise ?? ''
              })
            }}
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { renderMarkdown } from '@/lib/markdown';
import { SwiperBinding } from '@/lib/types/swiper';
import { replacePlaceholders } from '@/lib/utils';

const { countryContext, isContinent } = defineProps<{
  countryContext?: string;
  isContinent?: boolean;
}>();
const route = useRoute();

// Determine if we filter by continent or country
const filter = isContinent
  ? { countries: { continents: { slug: route.params.continentid } } }
  : { countries: { slug: countryContext } };
console.log('filter', filter);
const { expertsSlider: experts } = await useFindExpertsSlider({
  filters: filter
});

const rootStore = useRootStore();
const expertsSection = rootStore.common?.expertsSection;
const { title, link, CTA: cta, contact } = expertsSection ?? {};
const phoneNumbersStore = usePhoneNumbersStore();
const swiper = $ref<SwiperBinding>();
const breakpoint = useBreakpoints();
const expertSelected = $computed(() => experts?.[swiper?.realIndex ?? 0]);

const contactHtml = $computed(
  () =>
    renderMarkdown(
      replacePlaceholders(contact ?? '', { expertise: expertSelected?.attributes.expertise ?? '' })
    ).rendered
);
const phones = $computed(() => {
  const phoneNumber = phoneNumbersStore.getPhoneCountry(
    expertSelected?.attributes?.countries.data[0]?.attributes.slug
  );
  const phoneNumberWithoutFirstNumber = phoneNumber?.slice(1) ?? '';

  return [
    {
      from: 'Depuis la France',
      fromMobile: 'France',
      value: phoneNumber
    },
    {
      from: "Depuis l'Étranger",
      fromMobile: 'Étranger',
      value: `+33 ${phoneNumberWithoutFirstNumber}`
    }
  ];
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/breakpoints.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/misc.scss';
@use '$/mouse.scss';

.experts-container {
  justify-content: center;
  padding: 60px 0;

  @include breakpoints.mobile() {
    padding: 40px metrics.$mobile-inner-margin;
  }

  .experts {
    flex-direction: column;
    gap: 40px;
    width: metrics.$inner-width;

    @include breakpoints.mobile() {
      gap: 12px;
      width: 100%;
    }

    .title-container {
      align-items: center;
      justify-content: space-between;

      @include breakpoints.mobile() {
        flex-direction: column;
        align-items: flex-start;
      }

      .more {
        @include misc.bold-link();

        @include breakpoints.mobile() {
          align-self: end;
        }
      }
    }

    .content {
      gap: 48px;
      align-items: center;

      @include breakpoints.mobile() {
        flex-direction: column-reverse;
        gap: 32px;
        margin-top: 12px;
      }

      .info {
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        height: 313px;

        @include breakpoints.mobile() {
          height: auto;
        }

        .name {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
        }

        .title {
          gap: 5px;

          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          color: colors.$grey-700;

          @include breakpoints.mobile() {
            font-size: 16px;
            line-height: 19px;
          }

          .country {
            @include misc.bold-link();

            margin-top: -1px;
            font-weight: 400;
          }
        }

        .speech {
          margin-top: 12px;
          font-size: 18px;
          line-height: 26px;

          @include breakpoints.mobile() {
            font-size: 16px;
            line-height: 24px;

            -webkit-line-clamp: 6;
          }
        }
      }

      .selector {
        flex-direction: column;
        gap: 40px;

        @include breakpoints.mobile() {
          width: 100vw;
        }

        .slider-container {
          $slider-height: 180px;
          $slider-height-mobile: 150px;

          gap: 6px;
          align-items: center;
          height: $slider-height;

          @include breakpoints.mobile() {
            height: $slider-height-mobile;
          }

          .arrow {
            @include breakpoints.mobile() {
              position: absolute;
              z-index: 100 !important;

              &.right {
                right: 0;
              }
            }
          }

          .photos {
            width: 540px;

            @include breakpoints.mobile() {
              gap: 16px;
              width: 100%;
              padding: 0 calc(#{metrics.$mobile-inner-margin} + 12px);
            }

            &:deep(.swiper-wrapper) {
              align-items: center;
            }

            // eslint-disable-next-line vue-scoped-css/no-unused-selector
            .swiper-slide-active {
              z-index: 1;
            }

            .photo {
              $size: 120px;

              width: fit-content;
              height: $size;

              opacity: 0.4;
              object-fit: cover;
              border-radius: math.div($size, 2);

              transition-duration: 0.2s;
              transition-property: width, height, opacity;

              @include breakpoints.mobile() {
                height: 120px;
              }

              &.selected {
                height: $slider-height;
                opacity: 1;

                @include breakpoints.mobile() {
                  height: $slider-height;
                }
              }
            }
          }
        }
      }
    }

    .hours-and-phone {
      flex-direction: column;
      gap: 24px;
      align-items: center;

      padding: 24px 58px;

      border: 1px dashed colors.$primary-black;

      @include breakpoints.mobile() {
        align-items: flex-start;
        padding: 16px;
      }

      .hours {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        font-size: 20px;
        line-height: 25px;

        @include breakpoints.mobile() {
          font-size: 16px;
          line-height: 20px;
          text-align: left;
        }

        :deep(ul),
        :deep(ol) {
          margin-top: 12px;
          margin-left: 24px;

          @include breakpoints.mobile() {
            margin-left: 18px;
          }
        }

        :deep(ul) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          li {
            text-align: right;
          }
        }
      }

      .phones {
        gap: 48px;

        @include breakpoints.mobile() {
          flex-direction: column;
          gap: 14px;
        }

        .phone {
          gap: 8px;
          align-items: center;
          font-size: 18px;
          line-height: 27px;

          @include breakpoints.mobile() {
            gap: 6px;
            font-size: 16px;
          }

          .icon {
            @include breakpoints.mobile() {
              transform: scale(0.6);
            }
          }

          .label {
            @include breakpoints.mobile() {
              display: none;
            }

            &.mobile {
              display: none;

              @include breakpoints.mobile() {
                display: flex;
              }
            }
          }

          .value {
            font-weight: 700;
          }
        }
      }
    }

    .contact-container {
      justify-content: center;

      @include breakpoints.mobile() {
        margin-top: 8px;
      }

      .contact {
        @include button.plain($size: big);

        text-align: center;

        @include breakpoints.mobile() {
          width: 300px;
        }
      }
    }
  }
}
</style>
